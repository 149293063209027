import React, { useState } from "react";
import Error from "../Error";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { StyledComponent } from "./style";

export interface TOCProps {
  form: any;
  toc: any;
  setTOC: any;
  setError: any;
  testId?: string;
}

export const Component: React.FC<TOCProps> = ({ form, toc, setTOC, setError, testId = "" }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleClick = (event: any) => {
    event.preventDefault();
    if (toc === "false") {
      setTOC("true");
      setErrorMessage("");
      setError("");
      return;
    }
    setTOC("false");
    setErrorMessage(form?.error_messages.terms_agreement);
    setError(form?.error_messages.terms_agreement);
  };

  return (
    <StyledComponent>
      <label htmlFor="agreedToTerms" className="TOC-message" data-testid={`${testId}:toc-label`}>
        <>{ReactHtmlParser(form?.opt_in_message)}</>
        <input
          type="checkbox"
          name="agreedToTerms"
          checked={toc === "true" ? true : false}
          value={toc === "true" ? "true" : "false"}
          readOnly
          data-testid={`${testId}:toc-input`}
        />
        <span className="checkmark" onClick={(event) => handleClick(event)} data-testid={`${testId}:toc-checkmark`}></span>
        {errorMessage && <Error error={errorMessage} testId={`${testId}:toc-error-message`}/>}
      </label>
    </StyledComponent>
  );
};

export default Component;
