import React, { useState } from "react";
import Helmet from "react-helmet";

export interface HeadProps {
  seo: any;
  publishDetails: any;
}

export const Component: React.FC<HeadProps> = ({ seo, publishDetails }) => {
  const metaDescription = seo.description;
  return (
    <Helmet
      htmlAttributes={{
        lang: publishDetails.locale,
      }}
      titleTemplate={seo.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "WWPUB",
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1, shrink-to-fit=yes`,
        },
      ]}
    >
      <title>{seo.title}</title>
      <script type="text/javascript" src="https://www.youtube.com/iframe_api" />
    </Helmet>
  );
};

export default Component;
