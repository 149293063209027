import React, { useState, useLayoutEffect } from "react";
import { StyledComponent } from "./style";
import logo from "../../images/tellstones-wordmark.svg";
import PlyrVideo from "../PlyrVideo";
import ShopifyBuyButton from "../ShopifyBuyButton";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { LangLocale } from "../../helpers/rioti18n";
import getClient from "../../helpers/shopify-client";

export interface HeroProps {
  hero: any;
  lang: any;
}

export const Component: React.FC<HeroProps> = ({ hero, lang }) => {
  // console.log(JSON.stringify(hero));
  const client = getClient(lang);

  const [merchPrice, setMerchPrice] = useState(hero?.price);
  const merchProductHandle = lang === "de-de" ? `tellstones-board-game-german` : `tellstones-board-game-english`;

  const dynamicPrice = async () => {
    try {
      const product = await client.product.fetchByHandle(merchProductHandle);
      // console.log(product.variants[0].priceV2);
      setMerchPrice(
        Intl.NumberFormat(lang, {
          style: "currency",
          currency: (product.variants[0] as any).priceV2.currencyCode,
        }).format((product.variants[0] as any).priceV2.amount),
      );
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  useLayoutEffect(() => {
    if (merchPrice === hero?.price) {
      dynamicPrice();
    }
  }, [merchPrice]);

  return (
    <StyledComponent className="hero" data-testid="hero">
      <div className="row content">
        <div className="column left">
          <div className="row video">
            <PlyrVideo youtubeId={hero?.video_id} testId="hero"/>
          </div>
        </div>
        <div className="column right">
          <div className="center logo">
            <div className="img-container">
              <img src={logo} data-testid="hero:logo"/>
            </div>
            <h5>{hero?.logo_tagline}</h5>
          </div>
          {hero?.buy_now_enabled && merchPrice && (
            <>
              <ShopifyBuyButton lang={lang} cta={hero?.header_cta} />
              <p className="price" data-testid="hero:buy-butotn">{merchPrice ? merchPrice : hero?.price}</p>
            </>
          )}
        </div>
      </div>
      <div className="row disclaimer">
        <div data-testid="hero:disclaimer">{ReactHtmlParser(hero?.disclaimer)}</div>
      </div>
    </StyledComponent>
  );
};

export default Component;
