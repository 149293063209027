import Client from "shopify-buy";

const getClient = (lang: string): ShopifyBuy.Client => {
  const shopifyDomain = ["de-de", "el-gr", "es-es", "fr-fr", "it-it", "pl-pl", "hu-hu", "ro-ro", "cs-cz", "en-gb"].includes(lang)
    ? process.env.GATSBY_SHOPIFY_DOMAIN_EU
    : process.env.GATSBY_SHOPIFY_DOMAIN;

  const shopifyToken = ["de-de", "el-gr", "es-es", "fr-fr", "it-it", "pl-pl", "hu-hu", "ro-ro", "cs-cz", "en-gb"].includes(lang)
    ? process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_EU
    : process.env.GATSBY_SHOPIFY_ACCESS_TOKEN;
  const client = Client.buildClient({
    domain: shopifyDomain || "",
    storefrontAccessToken: shopifyToken || "",
  });
  return client;
};

export default getClient;
