import React from "react";

export interface MultilineProps {
  text: any;
  testId?: string;
}

export const Component: React.FC<MultilineProps> = ({ text, testId = "" }) => {
  return (
    <>
      {text?.map((line: string, index: number) => {
        return <h1 key={Math.random()} data-testid={`${testId}:title-${index}`}>{line}</h1>;
      })}
    </>
  );
};

export default Component;
