import styled from "styled-components";
import { size } from "../Layout/theme";

export const StyledComponent = styled.div`
  display: column;
  max-width: 480px;
  margin: auto;

  @media (max-width: ${size.laptop}) {
    margin: 0;
    max-width: 1000px;
  }

  p.download-title {
    padding: 40px 0;
  }

  section.download-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 20px auto -200px;

    @media (max-width: ${size.tablet}) {
      flex-direction: column;
    }

    @media (max-width: ${size.tablet}) {
      height: 500px;
    }

    .img-container {
      margin-left: -8px;

      @media (max-width: ${size.tablet}) {
        margin-left: 0;
        margin-bottom: 20px;
      }
    }

    .download-controls {
      display: block;
      padding-left: 18px;

      @media (max-width: ${size.tablet}) {
        padding-left: 0;
        margin-left: -16px;
      }

      .rulebook-selector {
        position: relative;
        padding-bottom: 20px;
        margin-left: 20px;
        flex: 1;
        max-width: 300px;

        .dropdown-header {
          background-color: ${(props) => props.theme.tellstones_offwhite};
          border: 3px solid ${(props) => props.theme.tellstones_navy};
          padding: 12px;
          width: 100%;
        }

        .dropdown-list-container {
          position: absolute;
          z-index: 2;
          width: 100%;
          display: block;
          border: 3px solid ${(props) => props.theme.tellstones_navy};
          overflow: scroll;
          height: 300px;
          background-color: ${(props) => props.theme.tellstones_offwhite};
        }

        ul.dropdown-list {
          li.dropdown-list-item {
            padding: 12px;
            width: 100%;
            border-bottom: 1px solid ${(props) => props.theme.tellstones_navy};

            &:first-child {
              border-top: 0;
            }

            &:last-child {
              border-bottom: none;
            }

            &:hover {
              color: ${(props) => props.theme.tellstones_offwhite};
              background-color: ${(props) => props.theme.tellstones_navy};
            }
          }
        }
      }

      .download-button a > .tellstones-button {
        margin: 0;
        flex: 1;
        z-index: 1;
        text-transform: uppercase;
      }

      button.tellstones-button.long {
        height: 90px;
        margin-left: 20px;
        text-transform: uppercase;
      }
    }
  }
`;
