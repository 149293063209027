import styled from "styled-components";
import pageDivider from "../../images/page-divider.svg";
import { size } from "../Layout/theme";

export const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  background-color: ${(props) => props.theme.tellstones_white};
  padding: 75px;
  border-image-source: url(${pageDivider});
  border-image-slice: 100;
  border-image-width: 0 0 40px 0;
  border-image-outset: 20px;
  border-width: 0 0 1px 0;
  border-image-repeat: stretch;
  position: relative;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
  }

  .image-container {
    img {
      object-fit: contain;
      margin: auto;
    }
  }

  .product-shot-1-container {
    position: absolute;
    top: -100px;
    left: 0;
    z-index: 2;
    width: 100%;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      position: relative;
      top: 0px;
    }

    .image {
      object-fit: contain;
      margin: auto;
    }
  }

  .overview-description-container {
    padding-top: 10vw;

    @media (max-width: ${size.tablet}) {
      flex-direction: column-reverse;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
    }

    .overview-divider {
      margin: 0;
    }

    .game-specs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0;
      min-width: 248px;
      max-width: 360px;
      max-height: 120px;
      max-width: 360px;
      font-size: 12px;
      font-weight: 600;
      background-color: ${(props) => props.theme.tellstones_offwhite};
      padding: 20px 0;
      margin-top: 20px;

      @media (max-width: ${size.tablet}) {
        margin: 20px 0;
      }

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        // IE10+ specific styles go here
        display: -ms-grid;
        -ms-grid-columns: 1fr 1fr 1fr;
        margin: 20px 0 0;
      }

      & > div {
        padding: 10px;
        margin: 0;

        &:nth-child(even) {
          border: 1px solid ${(props) => props.theme.tellstones_navy};
          border-top: none;
          border-bottom: none;
        }

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          // IE10+ specific styles go here
          &:nth-child(1) {
            -ms-grid-row: 1;
            -ms-grid-column: 1;
          }
          &:nth-child(2) {
            -ms-grid-row: 1;
            -ms-grid-column: 2;
          }
          &:nth-child(3) {
            -ms-grid-row: 1;
            -ms-grid-column: 3;
          }
        }

        .img-container {
          margin: auto;
          height: 24px;
          width: 24px;
          img {
            width: 100%;
          }
        }

        p {
          margin: 10px 0 0;
          line-height: 1;
        }
      }
    }
  }

  .quality-description-container {
    padding-top: 60px;

    @media (max-width: ${size.tablet}) {
      flex-direction: column;
      padding-bottom: 60px;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
      padding-bottom: 100px;
    }

    .quality-description {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media (max-width: ${size.tablet}) {
        padding-bottom: 20px;
      }

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
      }

      .overview-corner {
        margin: 0;

        @media (max-width: ${size.tablet}) {
          margin-left: -20px;
        }
      }

      .text-block {
        margin: 0 70px;
        text-align: right;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          margin-bottom: 20px;
        }

        @media (max-width: ${size.tablet}) {
          margin: 0 0 20px 40px;
          text-align: left;
        }
      }
    }

    .carousel img {
      margin-left: 0;
    }
  }

  .floating-coin {
    position: absolute;
    bottom: -140px;
    left: 0;
    z-index: 2;
    width: 50%;
    min-width: 512px;
    padding-right: 200px;

    @media (max-width: ${size.tablet}) {
      min-width: 480px;
      bottom: -100px;
    }

    .image {
      object-fit: contain;
      margin: 0;
    }
  }
`;
