import styled from "styled-components";

export const MessageWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 100%;
  left: 0;
  box-sizing: border-box;
  margin: auto;
  opacity: 1;
  visibility: visible;
  transition: max-height 300ms ease-out, opacity 150ms linear 150ms, visibility 0ms;

  .triangle {
    width: 0;
    height: 0;
    margin-left: calc(50% - 5px);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
  }
`;

export const FormErrorMessage = styled.div`
  display: flex;
  align-items: center;
  font-family: "Arial", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0em;
  text-transform: none;
  color: #444444;
  padding: 8px;
  margin: auto;
  text-align: left;
  background: white;
  border-radius: 3px;

  .img-container {
    display: block;
    margin-right: 10px;
    padding: 7px;
    border-radius: 3px;
    width: 28px;
    height: 28px;
    background-color: #be29cc;

    img {
      object-fit: contain;
      width: 14px;
      height: 14px;
    }
  }
`;
