import styled from "styled-components";
import { size } from "../Layout/theme";
import Divider from "../../images/overview-divider.svg";

export const StyledComponent = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.tellstones_white};

  @media (max-width: ${size.laptop}) {
  }

  .item {
    display: block;
    position: relative;

    margin: 0;
    margin-top: -20px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;
      width: 100%;
      padding: 40px 20px 20px;
      font-weight: 800;
      line-height: 155%;
      letter-spacing: 0.05em;
      border-image-source: url(${Divider});
      border-image-slice: 100;
      border-image-width: 40px 0 0px 0;
      border-image-outset: 0;
      border-width: 0 0 1px 0;
      border-image-repeat: stretch;

      &:hover {
        .arrow-up {
          transform: rotate(180deg);
        }
      }

      .arrow-up {
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid ${(props) => props.theme.tellstones_navy};
        transition: 0.2s ease-in-out;
        transform: rotate(-180deg);
      }
    }

    .content-container {
      z-index: 2;
      display: block;
      width: 100%;
      padding: 20px;
      font-size: 12px;
      line-height: 155%;
      letter-spacing: -0.01em;
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: -1px;
      background-color: ${(props) => props.theme.tellstones_white};

      a {
        text-decoration: underline;
      }
    }
  }
`;
