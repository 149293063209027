import React from "react";
import { StyledComponent } from "./style";
import Collapsible from "../Collapsible";

export interface FAQProps {
  faq: any;
}

export const Component: React.FC<FAQProps> = ({ faq }) => {
  return (
    <StyledComponent className="faq" data-testid="faq">
      <h1 data-testid="faq:title">{faq.faq_title}</h1>
      <div className="faq-grid" data-testid="faq:grid">
        {faq?.accordion.map((item: any, index: number) => (
          <Collapsible question={item.accordion_title} answer={item.accordion_description} key={Math.random()} testId={`faq:grid-${index}`}/>
        ))}
      </div>
    </StyledComponent>
  );
};

export default Component;
