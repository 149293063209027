import styled from "styled-components";
import bgd from "../../images/backdrop-tabletop.png";
import { size } from "../Layout/theme";

export const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  flex-basis: auto;
  margin: auto;
  background-image: url(${bgd});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${size.laptop}) {
    padding: 0;
  }

    .toc-light {
      display: block;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      max-width: 506px;
    }

    /* Hide the browser's default checkbox */
    .toc-light input {
      visibility: hidden;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 15px;
      width: 15px;
      background: transparent;
      background: rgba(196, 196, 196, 0.1);
      border: 1px solid #262626;
      box-sizing: border-box;
    }

    /* On mouse-over, add a grey background color */
    .toc-light:hover input ~ .checkmark {
      background-color: grey;
    }

    /* When the checkbox is checked, add a grey background */
    .toc-light input:checked ~ .checkmark {
      background-color: grey;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .toc-light input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .toc-light .checkmark:after {
      left: 5px;
      top: 2px;
      width: 4px;
      height: 7px;
      border: solid ${(props) => props.theme.tellstones_offwhite};
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;
