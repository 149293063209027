import React from "react";
import { StyledComponent } from "./style";
import FormSignupTabletop from "../../components/FormSignupTabletop";

export interface FooterProps {
  footer: any;
  lang: any;
}

export const Component: React.FC<FooterProps> = ({ footer, lang }) => {
  // console.log(JSON.stringify(footer));
  return (
    <StyledComponent className="footer">
      <FormSignupTabletop form={footer} lang={lang} />
    </StyledComponent>
  );
};

export default Component;
