import styled from "styled-components";
import { size } from "../Layout/theme";

export const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.tellstones_white};
  padding: 59px 82px;

  h1 {
    margin: auto auto 59px;
  }

  .faq-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;
    column-gap: 20px;

    @media (max-width: ${size.laptop}) {
      flex-direction: column;
    }

    @media (max-width: ${size.tablet}) {
      display: block;
    }
  }
`;
