import styled from "styled-components";
import bgd from "../../images/backdrop-hero.jpg";
import { size } from "../Layout/theme";

export const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6em;
  background-image: url(${bgd});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${size.laptop}) {
    padding: 2em;
    padding-bottom: 8em;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
    margin: 0;
  }

  .content {
    @media (max-width: ${size.laptop}) {
      display: flex;
      flex-direction: column-reverse;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: grid;
      display: -ms-grid;
      -ms-grid-columns: 1fr 1fr;
      margin: 0;
    }
  }

  .left {
    flex: 1;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      // IE10+ specific styles go here
      display: block;
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    .video {
      @media (max-width: ${size.laptop}) {
        width: 70vw;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px;

    @media (max-width: ${size.laptop}) {
      margin: 40px 0;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      // IE10+ specific styles go here
      display: block;
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }

    .logo {
      .img-container {
        height: 100%;

        img {
          margin: auto;
          width: 100%;
          min-width: 500px;
          height: auto;
          max-height: 18vh;

          @media (max-width: ${size.mobileXL}) {
            min-width: 200px;
          }

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            min-width: 200px;
            max-width: 344px;
            max-height: 588px;
            margin: auto;
          }
        }
      }
    }

    .buy-button {
      margin: 0;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin: 20px auto;
      }

      .tellstones-button {
        margin: 20px;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          margin: 20px auto;
        }
      }
    }

    .price {
      font-weight: 600;
      margin: 40px 40px 10px;

      @media (max-width: ${size.laptop}) {
        margin: 0px 20px;
      }

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 100%;
        text-align: center;
        margin: 20px auto;
      }
    }
  }

  .disclaimer {
    margin-top: 38px;
    background-color: ${(props) => props.theme.tellstones_offwhite};

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      // IE10+ specific styles go here
      display: block;
      margin: 20px;
    }

    a {
      text-decoration: underline;
    }

    div {
      margin: 20px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        // IE10+ specific styles go here
        margin: 20px;
      }
    }
  }
`;
