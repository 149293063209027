import React from "react";
import { StyledComponent } from "./style";

export interface ButtonProps 
{
  testId?: string;
}

export const Component: React.FC<ButtonProps> = ({testId = ""}) => {
  return (
    <StyledComponent className="footer">
      <a className="video-player-button" data-testid={testId}></a>
    </StyledComponent>
  );
};

export default Component;
