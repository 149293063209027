import React from "react";
import Layout from "../components/Layout";
import { PageProps } from "gatsby";
import { LangLocale } from "../helpers/rioti18n";

import Head from "../components/Head";
import Hero from "../components/Hero";
import Overview from "../components/Overview";
import Details from "../components/Details";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import Promo from "../components/Promo";
import RiotBar from "@riotgames/riotbar-react";

export interface HomePageProps {}

export interface HomePageData {
  page: any;
  language: any;
  environment: string;
}

export interface HomePageContentstackProps extends PageProps<HomePageProps, HomePageData> {}

const IndexPage = (props: HomePageContentstackProps) => {
  let language = props.pageContext.page.publish_details.locale;
  let environment = props.pageContext.environment;
  let page = props.pageContext.page;

  return (
    <Layout>
      <RiotBar product="tellstones" environment={environment} locale={LangLocale[language]} />
      <Head seo={page?.seo} publishDetails={page?.publish_details} />
      <Hero hero={page?.header_hero} lang={language} />
      <Overview overview={page?.game_overview} hero={page?.header_email_sign_up} lang={language} />
      <Details details={page?.game_information} lang={language} />
      <FAQ faq={page?.faq} />
      {language !== "ja-jp" ? <Footer footer={page?.footer_email_sign_up} lang={language} /> : <></>}
      <Promo promo={page?.promo_module} />
    </Layout>
  );
};
export default IndexPage;
