import styled from "styled-components";
import buttonResting from "../../images/btn-plyr-resting.png";
import buttonHover from "../../images/btn-plyr-hover.png";
import buttonClick from "../../images/btn-plyr-click.png";

export const StyledComponent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;

  .video-player-button {
    width: 112px;
    height: 112px;
    background-image: url(${buttonResting});
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;

    &:hover {
      background-image: url(${buttonHover});
    }

    &:active {
      background-image: url(${buttonClick});
    }
  }
`;
