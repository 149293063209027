import styled from "styled-components";
import { size } from "../../Layout/theme";

export const StyledComponent = styled.div`
  text-transform: uppercase;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
    width: 100%;
  }

  label.TOC-message > p {
    text-transform: uppercase;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
      width: 100%;
    }
  }

  a {
    text-decoration: underline !important;
  }
`;
