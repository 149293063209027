import React, { useState } from "react";
import { FormErrorMessage, MessageWrapper } from "./style";
import Exclamation from "../../../images/exclamation.svg";

export interface ErrorProps {
  error: any;
  testId?: string;
}

export const Component: React.FC<ErrorProps> = ({ error, testId = "" }) => {
  return (
    <MessageWrapper className="active" data-testid={testId}>
      <div className="triangle" />
      <FormErrorMessage>
        <div className="img-container">
          <img src={Exclamation} />
        </div>
        {error}
      </FormErrorMessage>
    </MessageWrapper>
  );
};

export default Component;
