import React from "react";
import { StyledComponent } from "./style";
import product1 from "../../images/product-shot-1.png";
import product2 from "../../images/product-shot-2.png";
import product3 from "../../images/product-shot-3.png";
import pageDivider from "../../images/page-divider.svg";
import overviewDivider from "../../images/overview-divider.svg";
import overviewCorner from "../../images/overview-corner.svg";
import iconTime from "../../images/icon-time.svg";
import iconPlayers from "../../images/icon-players.svg";
import iconAge from "../../images/icon-age.svg";
import floatingCoin from "../../images/floating-coin.png";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

export interface OverviewProps {
  overview: any;
  hero: any;
  lang: string;
}

export const Component: React.FC<OverviewProps> = ({ overview, hero, lang }) => {
  // console.log(JSON.stringify(overview));
  return (
    <StyledComponent className="overview" data-testid="overview">
      <div className="row product-shot-1-container">
        <img className="image" src={product1} data-testid="overview:image-1"/>
      </div>
      <div className="row overview-description-container">
        <div className="row image-container">
          <img className="image" src={product2} data-testid="overview:image-2"/>
        </div>
        <div className="column left">
          <h1 data-testid="overview:title-1">{overview?.overview_headline}</h1>
          <div className="image-container">
            <img src={overviewDivider} className="overview-divider"/>
          </div>
          <div className="text-block" data-testid="overview:description-1">{ReactHtmlParser(overview?.overview_description)}</div>
          <div className="game-specs" data-testid="overview:game-specs">
            <div className="column center">
              <div className="img-container">
                <img src={iconTime} data-testid="overview:game-specs:game-length-icon"/>
              </div>
              <p data-testid="overview:game-specs:game-length-text">{overview?.game_length}</p>
            </div>
            <div className="column center">
              <div className="img-container">
                <img src={iconPlayers} data-testid="overview:game-specs:players-icon"/>
              </div>
              <p data-testid="overview:game-specs:players-text">{overview?.recommended_players}</p>
            </div>
            <div className="column center">
              <div className="img-container">
                <img src={iconAge} data-testid="overview:game-specs:age-icon"/>
              </div>
              <p data-testid="overview:game-specs:age-text">{overview?.recommended_age}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row quality-description-container">
        <div className="row">
          <div className="quality-description">
            <div className="image-container">
              <img src={overviewCorner} className="overview-corner"/>
            </div>
            <p className="text-block" data-testid="overview:description-2">{overview?.quality_description}</p>
          </div>
        </div>
        <div className="row image-container carousel">
          <img src={product3} data-testid="overview:image-3"/>
        </div>
      </div>
      <div className="row floating-coin">
        <img className="image" src={floatingCoin} data-testid="overview:image-4"/>
      </div>
    </StyledComponent>
  );
};

export default Component;
