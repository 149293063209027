import React from "react";
import { StyledComponent } from "./style";
import crownPuck from "../../images/puck-crown.png";
import scalePuck from "../../images/puck-scale.png";
import flagPuck from "../../images/puck-flag.png";
import dividerPuck from "../../images/divider-puck.png";
import Multiline from "../../helpers/multiline";
import PlyrVideo from "../PlyrVideo";
import Downloads from "../Downloads";

export interface DetailProps {
  details: any;
  lang: string;
}

export const Component: React.FC<DetailProps> = ({ details, lang }) => {
  return (
    <StyledComponent className="details" data-testid="details">
      <section className="row section-1">
        <div className="column description">
          <div className="column">
            <div className="img-container">
              <img src={crownPuck} className="puck" />
              <img src={dividerPuck} className="puck" />
            </div>
            <div className="column">
              <Multiline text={details?.strategy_title} testId="details:section-1"/>
              <p className="text-block" data-testid="details:section-1:description">{details?.strategy_description}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <img src="" />
        </div>
      </section>

      <section className="column section-2">
        <div className="row">
          <div className="column center text-container">
            <div className="img-container">
              <div className="img-container">
                <img src={scalePuck} className="puck" />
                <img src={dividerPuck} className="puck" />
              </div>
            </div>
            <Multiline text={details?.anywhere_title} testId="details:section-2"/>
            <p className="text-block center" data-testid="details:section-2:description">{details?.anywhere_description}</p>
          </div>
        </div>
      </section>

      <section className="row section-3">
        <div className="column description">
          <div className="column">
            <div className="img-container">
              <img src={flagPuck} className="puck" />
              <img src={dividerPuck} className="puck" />
            </div>
            <div className="column">
              <Multiline text={details?.how_to_play_title} testId="details:section-3"/>
              <p className="text-block" data-testid="details:section-3:description">{details?.how_to_play_description}</p>
            </div>
          </div>
        </div>
        <div className="row video">
          <PlyrVideo youtubeId={details?.video_id} testId="details:section-3"/>
        </div>
      </section>

      <section className="row section-4">
        <div className="left">
          <Downloads downloads={details} lang={lang} testId="details:downloads"/>
        </div>
        <div className="row right"></div>
      </section>
    </StyledComponent>
  );
};

export default Component;
