import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import Error from "../Error";
import moment from "moment";

export interface BirthdayProps {
  form: any;
  lang: any;
  birthdate: any;
  setBirthday: any;
  setError: any;
  testId?: string;
}

export const Component: React.FC<BirthdayProps> = ({ form, birthdate, lang, setBirthday, setError, testId = "" }) => {
  let locale = lang;

  const [selectionStart, setSelectionStart] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [formattedBirthday, setFormattedBirthday] = useState("");
  const [isFocused, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && isFocused) {
      inputRef.current.selectionStart = selectionStart;
      inputRef.current.selectionEnd = selectionStart;
    }
  });

  const isInputValid = () => {
    let tokens = formattedBirthday.split("/");
    const regex = /[A-Za-z]/g;

    // is birthdate field filled?
    if (!formattedBirthday) {
      setErrorMessage(form?.error_messages.invalid_dob);
      return false;
    }

    // does birthdate have month, day, year?
    if (
      !tokens[0] ||
      !tokens[1] ||
      !tokens[2] ||
      tokens[0].length !== 2 ||
      tokens[1].length !== 2 ||
      tokens[2].length !== 4
    ) {
      setErrorMessage(form?.error_messages.invalid_dob);
      return false;
    }

    // does birthdate use letters?
    if (regex.test(formattedBirthday)) {
      setErrorMessage(form?.error_messages.invalid_dob);
      return false;
    }

    // does birthdate use numbers?
    if (Number(tokens[0]) === NaN && Number(tokens[1]) === NaN && Number(tokens[2]) === NaN) {
      setErrorMessage(form?.error_messages.invalid_dob);
      return false;
    }

    // is birthdate year not in the future?
    let today = new Date();
    if (Number(tokens[2]) > today.getFullYear()) {
      setErrorMessage(form?.error_messages.invalid_dob);
      return false;
    }

    // is the user at least 13 years old?
    let now = moment();
    let cutoff = now.subtract(13, "years");
    if (moment(`${tokens[2]}-${tokens[0]}-${tokens[1]}`).isSameOrAfter(`${cutoff.year()}-${tokens[0]}-${tokens[1]}`)) {
      setErrorMessage(form?.error_messages.invalid_dob);
      return false;
    }

    // is birthdate month valid for US locale?
    if (locale === "en-us" && (1 > Number(tokens[0]) || Number(tokens[0]) > 12)) {
      setErrorMessage(form?.error_messages.invalid_dob);
      return false;
    }

    // is birthdate month valid for non-US locales?
    let nonUSbirthday = new Date(`${tokens[1]} ${tokens[0]}, ${tokens[2]}`);
    if (locale !== "en-us" && isNaN(nonUSbirthday.getTime())) {
      setErrorMessage(form?.error_messages.invalid_dob);
      return false;
    }

    // is birthdate day valid for US locales?
    if (locale === "en-us" && (1 > Number(tokens[1]) || Number(tokens[1]) > 31)) {
      setErrorMessage(form?.error_messages.invalid_dob);
      return false;
    }

    // is birthdate day valid for non-US locales?
    if (locale !== "en-us" && (1 > Number(tokens[0]) || Number(tokens[0]) > 31)) {
      setErrorMessage(form?.error_messages.invalid_dob);
      return false;
    }

    return true;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Backspace" || event.key === "Delete" || event.key === 8 || event.key === 46) {
      event.preventDefault();
      setFocus(true);
      let birthday =
        event.target.value.substring(0, event.target.selectionStart - 1) +
        event.target.value.substring(event.target.selectionStart, event.target.value.length);
      setSelectionStart(event.target.selectionStart - 1);
      setFormattedBirthday(birthday);
    }
  };

  const handleChange = (event: any) => {
    setFocus(true);
    setFormattedBirthday(event.target.value);
    setSelectionStart(event.target.selectionStart);
  };

  const handleBlur = (event: any) => {
    // format birthday
    let cleanedBirthday = formattedBirthday.trim().replace(/\//gi, "");
    let slashedBirthday =
      cleanedBirthday.slice(0, 2) +
      "/" +
      cleanedBirthday.slice(2, 4) +
      "/" +
      cleanedBirthday.slice(4, cleanedBirthday.length);
    setFormattedBirthday(slashedBirthday);

    // validate birthday
    if (isInputValid()) {
      setBirthday(slashedBirthday);
      setError("");
      return;
    }
    setError(form?.error_messages.invalid_dob);
    setFocus(false);
  };

  const handleFocus = (event: any) => {
    event.preventDefault();
    setFocus(true);
    setErrorMessage("");
  };

  return (
    <div className="birthday-field">
      <label htmlFor="birthdate" data-testid={`${testId}:birthdate-label`}>{form?.date_of_birth_field}</label>
      <input
        key="birthdate-input"
        type="text"
        id="birthdate-field"
        name="birthdate"
        value={formattedBirthday}
        ref={inputRef}
        placeholder={form?.date_of_birth_format}
        onFocus={(event) => handleFocus(event)}
        onKeyDown={(event) => handleKeyDown(event)}
        onChange={(event) => handleChange(event)}
        onBlur={(event) => handleBlur(event)}
        data-testid={`${testId}:birthdate-input`}
      ></input>
      {errorMessage && <Error error={errorMessage} testId={`${testId}:birthdate-error-message`}/>}
    </div>
  );
};

export default Component;
