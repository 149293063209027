import React, { useState } from "react";
import { StyledComponent } from "./style";
import getClient from "../../helpers/shopify-client";

export interface ShopifyProps {
  lang: string;
  cta: string;
}

export const Component: React.FC<ShopifyProps> = ({ cta, lang }) => {
  const longText = () => {
    if (["hu-hu", "cs-cz", "fr-fr", "it-it", "ro-ro", "es-mx", "es-ar", "pt-br", "ru-ru"].includes(lang)) {
      return "long";
    } else {
      return "";
    }
  };

  const client = getClient(lang);

  const handleClick = async (event: any) => {
    event.preventDefault();
    const merchProductHandle = lang === "de-de" ? `tellstones-board-game-german` : `tellstones-board-game-english`;

    // Get Shopify product ID through merch url_handle
    try {
      const product = await client.product.fetchByHandle(merchProductHandle);
      const variantID = product.variants[0].id;

      // Create an empty checkout
      let checkout = await client.checkout.create();
      const lineItemsToAdd = [
        {
          variantId: variantID,
          quantity: 1,
        },
      ];
      checkout = await client.checkout.addLineItems(checkout.id, lineItemsToAdd);
      window.location.href = `${(checkout as any).webUrl}&locale=${lang}`;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StyledComponent className="shopify-buy-button">
      <div className="buy-button" onClick={(event) => handleClick(event)}>
        <button className={`tellstones-button ${longText()}`} data-interaction-id="hero-buy_now">
          {cta}
        </button>
      </div>
    </StyledComponent>
  );
};

export default Component;
