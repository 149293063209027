import styled from "styled-components";
import bgd from "../../images/backdrop-hero.jpg";
import { size } from "../Layout/theme";

export const StyledComponent = styled.div`
  .buy-button {
    margin: 0;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin: 20px auto;
    }

    .tellstones-button {
      margin: 20px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin: 20px auto;
      }
    }

    button.tellstones-button.long {
      height: 90px;
    }
  }
`;
