import React, { useState } from "react";
import { StyledComponent } from "./style";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

export interface CollapsibleProps {
  question: string;
  answer: string;
  testId?: string;
}

export const Component: React.FC<CollapsibleProps> = ({ question, answer, testId = "" }) => {
  const [isOpen, setIsOpen] = useState(false);

  function handleClick(event: any) {
    event.preventDefault();
    // console.log("clicked");
    setIsOpen(!isOpen);
  }

  return (
    <StyledComponent className="collapsible">
      <div className="item">
        <div className="header" onClick={(event) => handleClick(event)}>
          <div data-testid={`${testId}:question`}>{ReactHtmlParser(question)}</div>
          <div className="arrow-up" data-testid={`${testId}:arrow`}></div>
        </div>
        {isOpen && <div className="content-container" data-testid={`${testId}:answer`}>{ReactHtmlParser(answer)}</div>}
      </div>
    </StyledComponent>
  );
};

export default Component;
