import React from "react";
import { StyledComponent } from "./style";
import mvm from "../../images/mvm-spread.png";

export interface PromoProps {
  promo: any;
}

export const Component: React.FC<PromoProps> = ({ promo }) => {
  return (
    <StyledComponent className="promo" data-testid="promo">
      <div className="img-container">
        <img src={mvm} data-testid="promo:image"/>
      </div>
      <div className="column cta">
        <div className="center">
          <h1 className="center" data-testid="promo:title">{promo?.title}</h1>
          <p className="center text-block" data-testid="promo:description">{promo?.description}</p>
          <a href={promo?.cta_link} data-testid="promo:link">
            <button className="rpg-button" data-testid="promo:button">{promo?.cta}</button>
          </a>
        </div>
      </div>
    </StyledComponent>
  );
};

export default Component;
