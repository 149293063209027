import styled from "styled-components";
import bgd1 from "../../images/fiora.png";
import bgd2 from "../../images/guys-handshake.png";
import bgd3 from "../../images/girl-victory.png";
import { size } from "../Layout/theme";

export const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // IE10+ styles here
    display: block;
  }

  .puck {
    width: 100%;
    max-width: 70px;
    margin: 20px 0;

    @media (max-width: ${size.tablet}) {
      margin: 20px auto;
    }
  }

  .section-1 {
    min-height: 800px;
    background-image: url(${bgd1});
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: ${size.tablet}) {
      padding: 120px 20px;
      min-height: 900px;
      display: block;
      text-align: center;
      background-position: right bottom;
      background-size: 150%;
    }

    .description {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-left: 68px;

      @media (max-width: ${size.tablet}) {
        padding-left: 0;
        display: block;
      }

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        // IE10+ styles here
        display: block;
        padding-top: 140px;
        text-align: left;
      }
    }

    .img-container {
      display: block;
      margin: 0;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin: auto auto 40px;
        height: 70px;
        width: 70px;
      }

      img {
        object-fit: contain;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .section-2 {
    min-height: 936px;
    background-image: url(${bgd2});
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0;

    @media (max-width: ${size.tablet}) {
      min-height: 700px;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      // IE10+ styles here
      display: block;
      margin: 0 auto;
      width: 100%;
    }

    .text-container {
      padding-bottom: 45vw;
      text-align: center;
      margin: auto;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        // IE10+ styles here
        display: block;
        width: 100%;
        margin: auto;
      }
    }

    .img-container {
      display: block;
      margin: auto;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        // IE10+ styles here
        width: 100%;
      }

      img {
        object-fit: cover;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          // IE10+ styles here
          height: auto;
          width: 70px;
          margin: auto;
        }
      }
    }
  }

  .section-3 {
    padding-left: 68px;

    @media (max-width: ${size.tablet}) {
      flex-direction: column;
      padding-left: 0px;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      // IE10+ specific styles go here
      display: -ms-grid;
      -ms-grid-columns: 1fr 1fr;
      margin: 0;
    }

    h1,
    .text-block {
      @media (max-width: ${size.tablet}) {
        text-align: center;
      }
    }

    .description {
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }
    }

    .video {
      margin: 68px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
      }
    }

    .img-container {
      display: block;
      margin: 0;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin: 0;
        height: 70px;
        width: 70px;
      }

      img {
        object-fit: contain;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          width: 100%;
        }
      }
    }
  }

  .section-4 {
    display: flex;
    flex-direction: row;
    background-image: url(${bgd3});
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 0px 68px 50vh;

    @media (max-width: ${size.mobileXL}) {
      padding: 0px 68px 42vh;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      // IE10+ styles here
      display: block;
      padding: 0px 68px 70vh;
    }

    .right {
      flex: 1;
      margin: auto;

      @media (max-width: ${size.laptop}) {
        flex: 0;
        display: hidden;
      }
    }

    .left {
      flex: 1;
      content: "";
      padding-bottom: 100px;
    }
  }
`;
