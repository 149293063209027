import React, { useState } from "react";
import { StyledComponent, StyledField, StyledTOC } from "./style";
import Multiline from "../../helpers/multiline";
import { LangLocale } from "../../helpers/rioti18n";

import EmailField from "../FormInputs/Email";
import BirthdayField from "../FormInputs/Birthday";
import TOCField from "../FormInputs/TOC";
import Error from "../FormInputs/Error";

export interface FormProps {
  form: any;
  lang: string;
}

export const Component: React.FC<FormProps> = ({ form, lang }) => {
  // console.log(lang);
  const [birthdate, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState("false");
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");

  const parseNonUSDate = (date: any) => {
    let tokens = date.split("/");
    return `${tokens[1]}/${tokens[0]}/${tokens[2]}`;
  };

  const formatDate = (date: any) => {
    let convertedDate = lang === "en-us" ? date : parseNonUSDate(date);
    return convertedDate.toString();
  };

  const postData = async () => {
    let returnURL = window.location.href || "";
    let salesforceKey = process.env.GATSBY_SALESFORCE_EXTERNAL_KEY;
    let today = new Date();
    let dateString = new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split("T")[0];

    try {
      // show postsignup state
      setIsSignedUp(true);

      // construct form data
      let formData = new FormData();
      formData.set("_clientID", "100025126");
      formData.set("_deExternalKey", salesforceKey || "");
      formData.set("_action", "add/update");
      formData.set("_returnXML", "1");
      formData.set("_successURL", returnURL);
      formData.set("_errorURL", returnURL);
      formData.set("puuid", email);
      formData.set("email address", email);
      formData.set("date of birth", formatDate(birthdate));
      formData.set("Riot Universe Publication List", "true");
      formData.set("language preference", LangLocale[lang]);
      formData.set("creation date", dateString);

      // post data to Salesforce
      await fetch("https://cl.exct.net/DEManager.aspx", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: formData,
      });
    } catch (err) {
      return;
    }
  };

  const handleClick = (event: any) => {
    event.preventDefault();
    if (!formErrorMessage && email && birthdate && agreedToTerms === "true") {
      postData();
      return;
    }
    setFormErrorMessage("Please fix form errors.");
  };

  return (
    <StyledComponent data-testid="tabletop-signup">
      {isSignedUp ? (
        <div className="post-signup">
          <Multiline text={form?.post_signup_header} testId="tabletop-signup:signup-confirmation"/>
          <p>{form?.post_signup_message}</p>
        </div>
      ) : (
        <form name="subscribeForm" className="tabletop-signup-form">
          <p className="subheader-dark" data-testid="tabletop-signup:subhead">{form?.subhead}</p>
          <h1 className="subheader-dark" data-testid="tabletop-signup:title">{form?.title}</h1>
          <StyledField>
            <EmailField
              form={form}
              email={email}
              setEmail={(email: any) => setEmail(email)}
              setError={(error: any) => setFormErrorMessage(error)}
              testId="tabletop-signup"
            />
          </StyledField>
          <StyledField>
            <BirthdayField
              form={form}
              lang={lang}
              birthdate={birthdate}
              setBirthday={(birthday: any) => setBirthday(birthday)}
              setError={(error: any) => setFormErrorMessage(error)}
              testId="tabletop-signup"
            />
          </StyledField>
          <StyledTOC>
            <TOCField
              form={form}
              toc={agreedToTerms}
              setTOC={(toc: any) => setAgreedToTerms(toc)}
              setError={(error: any) => setFormErrorMessage(error)}
              testId="tabletop-signup"
            />
          </StyledTOC>
          <div className="submit-button">
            <button
              disabled={formErrorMessage.length > 0 || birthdate === "" || email === "" || agreedToTerms === "false"}
              className="rpg-button"
              onClick={(event) => handleClick(event)}
              data-testid="tabletop-signup:signup-button"
            >
              {form?.email_cta}
            </button>
            {formErrorMessage && <Error error={formErrorMessage} testId="tabletop-signup:overall-error-message"/>}
          </div>
        </form>
      )}
    </StyledComponent>
  );
};

export default Component;
