import React, { useState } from "react";
import { StyledComponent } from "./style";
import rulebookThumbnail from "../../images/rulebook-thumbnail.png";

export interface DownloadsProps {
  downloads: any;
  lang: string;
  testId?: string;
}

export const Component: React.FC<DownloadsProps> = ({ downloads, lang, testId = "" }) => {
  // console.log(JSON.stringify(downloads));
  const options = downloads.rulebook_select;
  const [selectedOption, setSelectedOption] = useState(options[0]?.rulebook.url);
  const [dropdownLanguage, setDropdownLanguage] = useState(options[0]?.title);
  const [isOpen, setIsOpen] = useState(false);

  const longText = () => {
    if (["hu-hu", "cs-cz", "de-de", "fr-fr", "it-it", "ro-ro", "es-mx", "es-ar", "pt-br", "ru-ru"].includes(lang)) {
      return "long";
    } else {
      return "";
    }
  };

  function toggleDropdown(event: any) {
    event.preventDefault();
    setIsOpen(!isOpen);
  }

  function handleSelect(event: any, option: any) {
    event.preventDefault();
    setSelectedOption(option.rulebook.url);
    setDropdownLanguage(option.title);
    setIsOpen(false);
    console.log(`${option.title} rulebook selected`);
  }

  function handleClick() {
    (window as any).dataLayer.push({
      language: lang,
    });
  }

  return (
    <StyledComponent className="downloads" data-testid={testId}>
      <h1 className="download-title" data-testid={`${testId}:title`}>{downloads?.download_title}</h1>
      <section className="download-section">
        <div className="img-container">
          <img src={rulebookThumbnail} data-testid={`${testId}:image`}/>
        </div>
        <div className="column download-controls">
          <div className="rulebook-selector">
            <div className="dropdown-header" onClick={(event: any) => toggleDropdown(event)} data-testid={`${testId}:locale-selector`}>
              {dropdownLanguage ? dropdownLanguage : options[0].title}
            </div>
            {isOpen && (
              <div className="dropdown-list-container">
                <ul className="dropdown-list">
                  {options.map((option: any, index: number) => (
                    <li
                      className="dropdown-list-item"
                      onClick={(event: any) => handleSelect(event, option)}
                      key={Math.random()}
                      data-testid={`${testId}:locale-selector:option-${index}`}
                    >
                      {option.title}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="download-button">
            <a
              href={selectedOption}
              target="_blank"
              data-interaction-id="prefaq-download_rulebook"
              onClick={() => handleClick()}
              data-testid={`${testId}:download-button`}
            >
              <button className={`tellstones-button ${longText()}`} data-testid={`${testId}:tellstones-download-button`}>{downloads?.download_cta}</button>
            </a>
          </div>
        </div>
      </section>
    </StyledComponent>
  );
};

export default Component;
