import styled from "styled-components";
import buttonResting from "../../images/btn-plyr-resting.png";
import buttonHover from "../../images/btn-plyr-hover.png";
import buttonClick from "../../images/btn-plyr-click.png";

export const StyledComponent = styled.div`
  display: block;
  width: 100%;
  height: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;

  .video-plyr-button {
    z-index: 2;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: auto;
    justify-content: center;
    align-items: center;
  }

  iframe {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
`;
