import React, { useState } from "react";
import { StyledComponent } from "./style";
import PlyrButton from "../PlyrButton";
import ReactPlayer from "react-player/youtube";

export interface VideoProps {
  youtubeId: string;
  testId?: string;
}

export const Component: React.FC<VideoProps> = ({ youtubeId, testId = "" }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const startVideo = (event: any) => {
    event?.preventDefault();
    setIsPlaying(!isPlaying);
    setIsButtonVisible(!isButtonVisible);
  };

  return (
    <StyledComponent className="video-plyr">
      {isPlaying ? (
        <></>
      ) : (
        <div className="video-plyr-button" onClick={(event) => startVideo(event)}>
          {isButtonVisible && <PlyrButton testId={`${testId}:video-button`} />}
        </div>
      )}
      <ReactPlayer
        url={`http://www.youtube.com/watch?v=${youtubeId}`}
        playing={isPlaying}
        width="100%"
        height="100%"
        controls={true}
        config={{
          youtube: {
            playerVars: {
              showinfo: 0,
              autohide: 1,
              modestbranding: 1,
              controls: 1,
            },
          },
        }}
        data-testid={`${testId}:video`}
      />
    </StyledComponent>
  );
};

export default Component;
