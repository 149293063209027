import styled from "styled-components";
import bgd from "../../images/backdrop-promo.png";
import { size } from "../Layout/theme";

export const StyledComponent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1em 0;
  background-image: url(${bgd});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${size.laptop}) {
    flex-direction: column;
    padding: 3em 1em;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: grid;
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr;
    margin: 0;
  }

  .img-container {
    display: block;
    margin: auto;
    padding: 60px;

    @media (max-width: ${size.laptop}) {
      padding: 0 0 40px;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      // IE10+ specific styles go here
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }

    img {
      object-fit: contain;
    }
  }

  .cta {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      // IE10+ specific styles go here
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }
  }
`;
