import React, { useState, useRef } from "react";
// import { StyledComponent } from "./style";
// import useInput from "../../../helpers/useInput";
import Error from "../Error";

export interface EmailProps {
  form: any;
  email: any;
  setEmail: any;
  setError: any;
  testId?: string;
}

export const Component: React.FC<EmailProps> = ({ form, email, setEmail, setError, testId = "" }) => {
  // const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");
  const [errorMessage, setErrorMessage] = useState("");
  const [localEmail, setLocalEmail] = useState("");
  const emailRef = useRef<HTMLInputElement>(null);

  const isInputValid = () => {
    // is email field filled?
    if (!localEmail) {
      setErrorMessage(form?.error_messages.invalid_email);
      return false;
    }

    // is email valid?
    if (!localEmail.includes("@") || !localEmail.includes(".")) {
      setErrorMessage(form?.error_messages.invalid_email);
      return false;
    }

    return true;
  };

  const handleBlur = (event: any) => {
    event.preventDefault();
    if (isInputValid()) {
      setEmail(localEmail);
      setError("");
      return;
    }
    setError(form?.error_messages.invalid_email);
  };

  const handleFocus = (event: any) => {
    event.preventDefault();
    setErrorMessage("");
  };

  // const handleClick = (event: any) => {
  //   event.preventDefault();
  //   emailRef?.current?.focus();
  // };

  return (
    <div className="email-field">
      <label htmlFor="email" data-testid={`${testId}:email-label`}>{form?.email_field}</label>
      <input
        key="email-input"
        type="text"
        id="email-field"
        name="email"
        ref={emailRef}
        placeholder={form?.email_field}
        value={localEmail}
        onChange={(event) => {
          event.preventDefault();
          setLocalEmail(event.target.value);
        }}
        onFocus={(event) => handleFocus(event)}
        onBlur={(event) => handleBlur(event)}
        data-testid={`${testId}:email-input`}
      />
      {errorMessage && <Error error={errorMessage} testId={`${testId}:email-error-message`}/>}
    </div>
  );
};

export default Component;
