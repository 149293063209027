import styled from "styled-components";
import { size } from "../Layout/theme";
import bgd from "../../images/backdrop-tabletop.png";

export const StyledTOC = styled.div`
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
  }

  font-family: ${(props) => props.theme.font_family_legal};
  color: ${(props) => props.theme.tellstones_white};
  font-size: 8.192px;
  line-height: 130%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-weight: bold;

  label {
    font-family: ${(props) => props.theme.font_family_main};
    color: ${(props) => props.theme.tellstones_white};
    font-size: 11px;
    line-height: 155%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-weight: bold;
  }

  /* Hide the browser's default checkbox */
  input {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background: transparent;
    background: rgba(196, 196, 196, 0.1);
    border: 1px solid #262626;
    box-sizing: border-box;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: grey;
  }

  /* When the checkbox is checked, add a grey background */
  input:checked ~ .checkmark {
    background-color: grey;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 7px;
    border: solid ${(props) => props.theme.tellstones_offwhite};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const StyledField = styled.div`
  position: relative;
  margin: 0;
  margin-bottom: 22px;

  .subheader-dark {
    @media (max-width: ${size.mobileXL}) {
      margin: 20px 0;
    }
  }

  .textfield {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
  }

  label {
    font-family: ${(props) => props.theme.font_family_main};
    color: ${(props) => props.theme.tellstones_white};
    font-size: 11px;
    line-height: 155%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-weight: bold;
  }

  input[type="text"] {
    border-radius: 0;
    border: 1px solid #262626;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.75);
    min-width: 200px;
    width: 100%;
    height: 68px;
    padding: 10px;
    margin-bottom: 22px;
    background: rgba(196, 196, 196, 0.1);
    font-family: ${(props) => props.theme.font_family_main};
    color: ${(props) => props.theme.tellstones_white};
    letter-spacing: 0.15em;
  }
`;

export const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  flex-basis: auto;
  margin: auto;
  background-image: url(${bgd});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${size.laptop}) {
    padding: 0;
  }

  .post-signup {
    background: rgba(0, 0, 0, 0.75);
    height: 100%;
    min-height: 412px;
    min-width: 375px;
    max-width: 500px;
    padding: 68px;
    text-align: center;

    h1 {
      color: ${(props) => props.theme.tellstones_white};
      margin-bottom: 20px;
    }
  }

  .tabletop-signup-form {
    background: rgba(0, 0, 0, 0.75);
    border: 1px solid #262626;
    border-radius: 0;
    box-sizing: border-box;
    padding: 54px 88px;
    width: fit-content;
    max-width: 600px;
    margin: 80px auto;

    @media (max-width: ${size.laptop}) {
      width: 100%;
      height: 100%;
      margin: 0;
      border: none;
      padding: 42px;
    }

    h1 {
      color: ${(props) => props.theme.tellstones_white};
      margin-bottom: 20px;
    }
  }
`;
